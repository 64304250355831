console.log('Ready javascript');

// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);


// #Header ,activar is-active-fixed al hacer scroll
window.onscroll = function() {myFunction()};
var header = document.getElementById("header");
function myFunction() {
    if (window.pageYOffset > 150) {
    header.classList.add("is-active-fixed");
  } else {
    header.classList.remove("is-active-fixed");
  }
}


var circle = document.querySelector('.material-btn');
var material_menu_wrapper = document.querySelector('.material-menu-wrapper');
var link = document.querySelector('.material-content').querySelectorAll('li');
var ham = document.querySelector('.material-hamburger');
var main = document.querySelector('#main');
var content = document.querySelector('.material-content');
var header__btn_menu = document.querySelector('.header__btn-menu');
var win = window;

function openMenu(event) {
 
  circle.classList.toggle('active');
  ham.classList.toggle('material-close');
  main.classList.toggle('active');
  material_menu_wrapper.classList.toggle('active');
  for (var i = 0; i < link.length; i++) {
    link[i].classList.toggle('active');
  }
  content.classList.toggle('active');
  event.preventDefault();
  event.stopImmediatePropagation();
}

function closeMenu() {
  if (circle.classList.contains('active')) {
    circle.classList.remove('active');
    for (var i = 0; i < link.length; i++) {
      link[i].classList.toggle('active');
    }
    ham.classList.remove('material-close');
    main.classList.remove('active');
    material_menu_wrapper.classList.remove('active');
    content.classList.toggle('active');
  }
}

circle.addEventListener('click', openMenu, false);

win.addEventListener('click', closeMenu, false);

header__btn_menu.addEventListener('click', openMenu, false);







