// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

require('@fancyapps/fancybox');

// Scripts
require('./modules/scripts');




$(document).ready(function(){
   
    var AOS = require('aos');
    // https://michalsnik.github.io/aos/
   AOS.init({duration:800,easing:'slide',once:true});


   var footer = document.getElementById('footer');
    footer.setAttribute('data-aos-once', 'false');

    // Sobrescribe la configuración de AOS para el footer
    window.addEventListener('scroll', function() {
        if (footer.getAttribute('data-aos-once') === 'false' && footer.getAttribute('data-aos')) {
            AOS.refreshHard();
        }
    });

});



	









